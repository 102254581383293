import {
  POST_USER_CHANGE_PASSWORD
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
import { DEBUG_MODE } from '../../constants';
  

export const postChangePassword = (postData) => async dispatch => {
  //Get user locale of the machine he is on
  const userLocale = General.getLocale();
  postData.langLoc = userLocale;

  const response = await axios.post(`account/resetpass`,postData);

  if (DEBUG_MODE){
    console.log(response.data);
  }

  dispatch({type: POST_USER_CHANGE_PASSWORD, payload: response.data});
};

import React, {Fragment,useEffect,useState} from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import PrivateRoute from './privateRoute';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import history from '../history';
import * as General from '../helpers/General'
import { AppIdProvider } from '../providers/AppIdProvider';
import NoAppIdComponent from './global/no-content/NoAppIdComponent';
import Terms from './terms';
import ChangePassword from './change-password';
import ChangePasswordSuccess from './change-password/ChangePasswordSuccess';

const MainApp = () => {

  //NOTIFICATION
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({title: '', body: ''});
  const [isTokenFound, setTokenFound] = useState(false);
  
  const [darkModeChecked, setDarkModeChecked] = useState(false);

  const theme = createTheme({
    components: {
      MuiTypography: {
        defaultProps: {
          variantMapping: {
            h1: 'h2',
            h2: 'h2',
            h3: 'h2',
            h4: 'h2',
            h5: 'h2',
            h6: 'h2',
            subtitle1: 'h2',
            subtitle2: 'h2',
            body1: 'span',
            body2: 'span',
          },
        },
      },
    },
    palette: {
      primary: {
        main: "#D21F3C",
      },
      secondary: {
        main: "#757575",
      },
      mode: darkModeChecked ? 'dark' : 'light',
      ...(!darkModeChecked
      ? {
      
          background: {
            default: "#f5f7fa",
            paper: "#ffffff",
          },
        }
      : {
        primary: {
          main: "#D21F3C",
        },
          secondary: {
            main: "#f5f7fa",
          },
          background: {
            default: "#1F2937",
            paper: "#030712",
          },
        }),
    },
  });

  const onModeChange = (isDarkChecked) => {
    setDarkModeChecked(isDarkChecked);
  }

  useEffect(()=>{
    setDarkModeChecked(General.getLightsMode() == "dark")
    },[]);
  
  return (
    <div> 
       <AppIdProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
      <ThemeProvider theme={theme}>
      <Fragment>
      <Router location={history.location} navigator={history}>
          <Routes>
          <Route path='/*' element={<NoAppIdComponent/>} /> 
            <Route path='/' element={<NoAppIdComponent/>} /> 
            <Route path='/:appId/terms/:lang/:fromApp' element={<PrivateRoute path={'/terms/:lang/:fromApp'}><Terms /></PrivateRoute>}/>
            <Route path='/:appId/change-password/:resetPassToken' element={<PrivateRoute path={'/change-password/:resetPassToken'}><ChangePassword /></PrivateRoute>}/>
            <Route path='/:appId/change-pass-success' element={<PrivateRoute path={'/change-pass-success'}><ChangePasswordSuccess /></PrivateRoute>}/>


            {/* <Route path='/:appId/' element={<PrivateRoute path={'/'}><Navigate to="app"></Navigate></PrivateRoute>}/>  */}

            {/* <Route path='/:appId/app/*' element={<PrivateRoute path={'app/*'}><Home onModeChange={onModeChange}/></PrivateRoute>}/> 
            <Route path='/:appId/login' element={<PrivateRoute path={'/login'}><Login /></PrivateRoute>}/> */}
          </Routes>
      </Router>
      </Fragment>
      </ThemeProvider>
      <ToastContainer/>
      </LocalizationProvider>
      </AppIdProvider>
    </div>
  );
};

export default MainApp;

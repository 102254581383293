import { POST_USER_CHANGE_PASSWORD } from "../actionTypes";

import * as Store from "../../helpers/Store";

const INIT_STATE = {
  changePasswordData: null,
};

const reducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case POST_USER_CHANGE_PASSWORD:
      return {
        ...state,
        changePasswordData: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;

import React, { createContext, useContext, useState, useEffect } from 'react';

// Create Context
const AppIdContext = createContext();

// Context Provider Component
export function AppIdProvider({ children }) {
  const [appId, setAppId] = useState('');

  useEffect(() => {
    const pathSegments = window.location.pathname.split('/');
    if (pathSegments.length > 1) {
      setAppId(pathSegments[1]); // Assuming the first segment after the domain is the app ID
    }
  }, []);

  return (
    <AppIdContext.Provider value={{ appId }}>
      {children}
    </AppIdContext.Provider>
  );
}

// Custom Hook to use the AppId
export function useAppId() {
  return useContext(AppIdContext);
}

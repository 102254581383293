import {
  GET_SETTINGS,
  APP_ID
  } from '../actionTypes';
  import axios from '../../helpers/Api'
  import * as General from '../../helpers/General'
  import * as Store from '../../helpers/Store';
import { DEBUG_MODE, MAIN_ID } from '../../constants';
  
  
  export const getSettings = (appId) => async dispatch => {
    //Here we will get the app init for the admin
    //We are going to pass the app id to the header

    //We store the app id for ever in our store
    Store.storeData(APP_ID, appId);

    //Then we will add a general header for the app id to be implemented automatically in all of the requests
    axios.defaults.headers.common['x-app-id'] = appId;
    //Also set the main id 
    axios.defaults.headers.common['x-main-app-id'] = MAIN_ID;

    axios.defaults.headers.common['x-sender'] = "app";
    
    //Get user locale of the machine he is on
    const userLocale = General.getLocale();

    let postData = {
      "langLoc": userLocale,
      "isAdmin": false
    };

    const response = await axios.post(`initData/init`,postData);
    
    if (DEBUG_MODE){
      console.log(response.data);
    }
  
    dispatch({type: GET_SETTINGS, payload: response.data});
  };

import React, {  } from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { useSelector } from "react-redux";
import CopyRightWidget from '../global/copyrightwidget/CopyrightWidget';

const ChangePasswordSuccess = (props) => {
 
  const settingsData = useSelector(({ settings }) => settings.settingsData);
  const trans = settingsData.trans;
  

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img src={settingsData.initData.app_logo} width="140px" alt="logo" style={{ margin: "10px" }} />

        <Typography component="h1" variant="h7">
          {trans.web_change_password_title}
        </Typography>
        <div style={{ marginTop: "10px", marginBottom: "10px" }}>
          {trans.web_change_password_success_desc}
        </div>
     
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
          <br/>
            <CopyRightWidget/>
      </Box>
    </Container>
  );
}

export default ChangePasswordSuccess;

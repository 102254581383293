import {combineReducers} from 'redux';


import settings from './settings/reducer';
import auth from './auth/reducer';

export default combineReducers({
    settings: settings,
    auth: auth
  });

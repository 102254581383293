import React from "react";
import { useSelector } from "react-redux";

const TermsEl = (props) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);

  return (
    <div>
      <h1>Όροι Χρήσης για την εφαρμογή {settingsData.initData.app_name}</h1>

      <div className="col-md-6">
        {/*<h1 style="text-align:center;">Όροι χρήσης</h1>*/}
        <p>
          Αυτή η πολιτική απορρήτου διέπει τη χρήση της εφαρμογής{" "}
          <strong>{settingsData.initData.app_name}</strong> για κινητές συσκευές που δημιουργήθηκε από την
          FOCUS-ON GROUP.{" "}
        </p>
        <h2 className="title" style={{ paddingTop: 20 }}>
          Ποιες πληροφορίες αποκτά η εφαρμογή και πώς αυτές χρησιμοποιούνται;
        </h2>
        <h2 className="title">Πληροφορίες που παρέχονται από τον χρήστη</h2>
        <p>
          Η Εφαρμογή αποκτά τις πληροφορίες που παρέχετε κατά τη λήψη και την
          εγγραφή σας στην Εφαρμογή. Η διαδικασία εγγραφής είναι προαιρετική.
          Ωστόσο, λάβετε υπόψη σας ότι ενδέχεται να μην μπορείτε να
          χρησιμοποιήσετε ορισμένες από τις λειτουργίες που προσφέρονται από την
          εφαρμογή, αν δεν εγγραφείτε.
          <br />
          <br />
          Όταν εγγράφεστε και χρησιμοποιείτε την Εφαρμογή, παρέχετε πληροφορίες
          όπως γενικά (α) το όνομα, τη διεύθυνση ηλεκτρονικού ταχυδρομείου, το
          όνομα χρήστη, τον κωδικό πρόσβασης και άλλες πληροφορίες εγγραφής. (β)
          πληροφορίες σχετικές με συναλλαγές, όπως όταν πραγματοποιείτε αγορές,
          να απαντάτε σε οποιεσδήποτε προσφορές ή να κάνετε λήψη ή χρήση
          εφαρμογών. (γ) τις πληροφορίες που μας παρέχετε όταν επικοινωνείτε
          μαζί μας για βοήθεια.(δ) πληροφορίες που εισάγετε στο σύστημά μας κατά
          τη χρήση της Εφαρμογής, όπως πληροφορίες επικοινωνίας και πληροφορίες
          διαχείρισης έργου. <br />
          <br />H επιχείρηση μας δεσμεύεται για την προστασία των προσωπικών σας
          δεδομένων και συμφωνεί με τη νέα Ευρωπαϊκή νομοθεσία σχετικά με την
          Προστασία Προσωπικών Δεδομένων (GDPR). Για την ορθή λειτουργία της
          εφαρμογής χρειάζεται να γνωρίζουμε ποιος έχει καταχωρήσει το αίτημα.
          Είναι απαραίτητη η καταχώρηση και η επεξεργασία των προσωπικών σας
          δεδομένων. Ποια προσωπικά δεδομένα χρειαζόμαστε:
        </p>
        <ul>
          <li> Το όνομά σας</li>
          <li> Τη διεύθυνσή σας</li>
          <li> Την ηλεκτρονική σας διεύθυνση</li>
          <li> Τηλέφωνο επικοινωνίας </li>
        </ul>
        Μπορείτε να τροποποιήσετε τα στοιχεία που καταχωρήσατε οποιαδήποτε
        στιγμή μπαίνοντας στην επιλογή επεξεργασίας του λογαριασμού σας Διαγραφή
        λογαριασμού: Μπορείτε να διαγράψεις το λογαριασμό σας, αλλά θα πρέπει να
        γνωρίζετε ότι αυτή η ενέργεια δεν είναι αναστρέψιμη. Εάν αλλάξετε γνώμη
        μετά τη διαγραφή σας, θα πρέπει να δημιουργήσετε λογαριασμό εκ νέου. Εάν
        θέλετε να διαγραφεί ο λογαριασμός σας, θα πρέπει να μπείτε στο μενού
        επικοινωνίας και να συμπληρώσετε την φόρμα επικοινωνίας.
        <br />
        <br />
        Μπορούμε επίσης να χρησιμοποιήσουμε τις πληροφορίες που μας δώσατε για
        να επικοινωνήσουμε μαζί για να σας παρέχουμε σημαντικές πληροφορίες,
        απαιτούμενες ειδοποιήσεις και προωθήσεις μάρκετινγκ.
        <p />
        <h2 className="title">Πληροφορίες που μας παρέχετε</h2>
        <p>
          Συλλέγουμε πληροφορίες που παρέχετε απευθείας σε εμάς, όπως όταν
          δημιουργείτε ή τροποποιείτε το λογαριασμό σας, όταν ζητάτε υπηρεσίες
          κατ 'απαίτηση, όταν επικοινωνείτε με την υποστήριξη πελατών. Αυτές οι
          πληροφορίες μπορεί να περιλαμβάνουν: όνομα, ηλεκτρονικό ταχυδρομείο,
          αριθμό τηλεφώνου, ταχυδρομική διεύθυνση, εικόνα προφίλ, τρόπο
          πληρωμής, στοιχεία που ζητούνται (για υπηρεσίες παράδοσης), δελτία
          παράδοσης και άλλες πληροφορίες που επιλέγετε να παρέχετε.
        </p>
        <h2 className="title">
          Πληροφορίες που συλλέγουμε μέσω της χρήσης των υπηρεσιών μας
        </h2>
        <p>
          Όταν χρησιμοποιείτε τις Υπηρεσίες μας, συλλέγουμε πληροφορίες σχετικά
          με εσάς στις ακόλουθες γενικές κατηγορίες:
        </p>
        <p>
          <span className="bold">Πληροφορίες τοποθεσίας:</span> Όταν
          χρησιμοποιείτε τις Υπηρεσίες για την παράδοση ή οποιεσδήποτε άλλες
          λειτουργίες της εφαρμογής, συλλέγουμε από εσάς ακριβή δεδομένα
          τοποθεσίας μόνο αφού έχετε επιτρέψει να γίνεται χρήση των υπηρεσιών
          τοποθεσίας. Η εφαρμογή χρησιμοποιεί αυτά τα δεδομένα για να παράσχει
          πληροφορίες σχετικά με τα δεδομένα που βασίζονται στην τοποθεσία σας.
        </p>
        <p>
          <span className="bold">Πληροφορίες Χρήσης και Προτιμήσεων:</span>{" "}
          Συλλέγουμε πληροφορίες σχετικά με τον τρόπο αλληλεπίδρασης με τις
          Υπηρεσίες μας, τις προτιμήσεις που εκφράζονται και τις ρυθμίσεις που
          επιλέξατε. Σε ορισμένες περιπτώσεις το κάνουμε αυτό μέσω της χρήσης
          cookies και παρόμοιων τεχνολογιών που δημιουργούν και διατηρούν
          μοναδικά αναγνωριστικά.
        </p>
        <p>
          <span className="bold">Πληροφορίες συσκευής:</span> Μπορούμε να
          συλλέξουμε πληροφορίες σχετικά με την κινητή συσκευή σας, όπως για
          παράδειγμα το μοντέλο, το λειτουργικό σύστημα και την έκδοση, το όνομα
          και τις εκδόσεις λογισμικού και αρχείων, την προτιμώμενη γλώσσα, το
          μοναδικό αναγνωριστικό συσκευής, τα αναγνωριστικά διαφήμισης, και
          πληροφορίες για το κινητό δίκτυο.
        </p>
        <p>
          <span className="bold">Πληροφορίες καταγραφής:</span> Όταν
          αλληλεπιδράτε με τις υπηρεσίες, συλλέγουμε αρχεία καταγραφής
          διακομιστών, τα οποία μπορεί να περιλαμβάνουν πληροφορίες όπως
          διεύθυνση IP συσκευής, ημερομηνίες και ώρες πρόσβασης, λειτουργίες
          εφαρμογών ή σελίδες που εμφανίζονται, σφάλματα εφαρμογών και άλλες
          δραστηριότητες συστήματος, τύπο προγράμματος περιήγησης, site ή
          υπηρεσία που χρησιμοποιούσατε πριν από την αλληλεπίδραση με τις
          Υπηρεσίες μας.
        </p>
        <h2 className="title">Αυτόματα συλλεγμένες πληροφορίες </h2>
        <p>
          Επιπλέον, η εφαρμογή μπορεί να συλλέγει αυτόματα ορισμένες
          πληροφορίες, συμπεριλαμβανομένων, μεταξύ άλλων, του τύπου της κινητής
          συσκευής που χρησιμοποιείτε, του μοναδικού αναγνωριστικού συσκευής
          σας, της διεύθυνσης IP της κινητής συσκευής, του λειτουργικού σας
          συστήματος κινητής τηλεφωνίας, του τύπου κινητού τηλεφώνου Τα
          προγράμματα περιήγησης στο Internet που χρησιμοποιείτε και πληροφορίες
          σχετικά με τον τρόπο χρήσης της εφαρμογής.
        </p>
        <h2 className="title">
          Η εφαρμογή συλλέγει ακριβείς πληροφορίες θέσης σε πραγματικό χρόνο της
          συσκευής;
        </h2>
        <p>
          Όταν επισκέπτεστε την εφαρμογή για κινητά, μπορούμε να
          χρησιμοποιήσουμε την τεχνολογία GPS (ή άλλη παρόμοια τεχνολογία) για
          να καθορίσουμε την τρέχουσα τοποθεσία σας, προκειμένου να
          προσδιορίσουμε την πόλη στην οποία βρίσκεστε και να προβάλλουμε έναν
          χάρτη τοποθεσίας με σχετικές διαφημίσεις. Δεν θα μοιραστούμε την
          τρέχουσα τοποθεσία σας με άλλους χρήστες ή συνεργάτες.
          <br />
          <br />
          Αν δεν θέλετε να χρησιμοποιήσουμε την τοποθεσία σας για τους σκοπούς
          που αναφέρονται παραπάνω, θα πρέπει να απενεργοποιήσετε τις υπηρεσίες
          τοποθεσίας για την εφαρμογή για κινητά, που βρίσκεται στις ρυθμίσεις
          του λογαριασμού σας ή στις ρυθμίσεις του κινητού σας τηλεφώνου ή / και
          στην εφαρμογή για κινητά.
        </p>
        <h2 className="title">
          Τα τρίτα μέρη βλέπουν και / ή έχουν πρόσβαση σε πληροφορίες που
          αποκτώνται από την εφαρμογή;
        </h2>
        <p>
          Ναι. Θα κοινοποιήσουμε τις πληροφορίες σας σε τρίτους μόνο με τους
          τρόπους που περιγράφονται σε αυτήν τη δήλωση προστασίας προσωπικών
          δεδομένων.
          <br />
          <br />
          Μπορούμε να αποκαλύψουμε πληροφορίες που παρέχονται από τον χρήστη και
          αυτόματα συλλεγμένες πληροφορίες:
          <br />
          • όπως απαιτείται από το νόμο, ώστε να συμμορφώνεται με κλήτευση ή
          παρόμοια νομική διαδικασία.
          <br />
          • όταν πιστεύουμε με καλή πίστη ότι η αποκάλυψη είναι απαραίτητη για
          την προστασία των δικαιωμάτων μας, την προστασία της ασφάλειας ή της
          ασφάλειας των άλλων, τη διερεύνηση της απάτης ή την ανταπόκριση σε
          κυβερνητικό αίτημα.
          <br />
          <br />
          • με τους αξιόπιστους παροχείς υπηρεσιών που δουλεύουν για λογαριασμό
          μας, δεν έχουν ανεξάρτητη χρήση των πληροφοριών που τους αποκαλύπτουμε
          και συμφωνήσαμε να τηρήσουμε τους κανόνες που ορίζονται στην παρούσα
          δήλωση προστασίας προσωπικών δεδομένων.
          <br />• εάν η εταιρεία συμμετέχει σε συγχώνευση, εξαγορά ή πώληση όλου
          ή μέρους των περιουσιακών στοιχείων της, θα ειδοποιηθείτε μέσω
          ηλεκτρονικού ταχυδρομείου
        </p>
        <h2 className="title">
          Ποια είναι τα δικαιώματα μου κατά την εξαίρεσή μου;
        </h2>
        <p>
          Μπορείτε να διακόψετε την συλλογή πληροφοριών από την εφαρμογή εύκολα,
          απεγκαθιστώντας την εφαρμογή. Μπορείτε να χρησιμοποιήσετε τις
          τυποποιημένες διαδικασίες απεγκατάστασης που είναι διαθέσιμες ως μέρος
          της κινητής σας συσκευής ή μέσω της αγοράς ή του δικτύου εφαρμογών για
          κινητά.
        </p>
        <h2 className="title">Ασφάλεια</h2>
        <p>
          H διασφάλιση της εμπιστευτικότητας των πληροφοριών σας είναι
          προτεραιότητα μας. Λάβετε υπόψη ότι αν και προσπαθούμε να παρέχουμε
          εύλογη ασφάλεια για τις πληροφορίες που επεξεργαζόμαστε και να
          διατηρούμε, κανένα σύστημα ασφαλείας δεν μπορεί να αποτρέψει όλες τις
          πιθανές παραβιάσεις της ασφάλειας.
        </p>
        <h2 className="title">Αλλαγές</h2>
        <p>
          Αυτή η Πολιτική Απορρήτου μπορεί να ενημερώνεται κατά διαστήματα για
          οποιονδήποτε λόγο. Θα σας ειδοποιήσουμε για οποιεσδήποτε αλλαγές στην
          Πολιτική Προστασίας Προσωπικών Δεδομένων, δημοσιεύοντας εδώ τη νέα
          Πολιτική Απορρήτου. Σας συμβουλεύουμε να βλέπετε τακτικά αυτή την
          Πολιτική Απορρήτου για οποιεσδήποτε αλλαγές, καθώς η συνεχής χρήση
          θεωρείται έγκριση όλων των αλλαγών.
        </p>
        <h2 className="title">Η συγκατάθεσή σας</h2>
        <p>
          Χρησιμοποιώντας την Εφαρμογή, συναινείτε στην επεξεργασία των
          πληροφοριών σας όπως αυτή ορίζεται στην παρούσα Πολιτική Απορρήτου
          τώρα και όπως τροποποιήθηκε από εμάς. "Επεξεργασία" σημαίνει χρήση
          cookies σε υπολογιστή / φορητή συσκευή ή χρήση ή επαφή με πληροφορίες
          με οποιονδήποτε τρόπο, συμπεριλαμβανομένων, μεταξύ άλλων, συλλογής,
          αποθήκευσης, διαγραφής, χρήσης, συνδυασμού και αποκάλυψης πληροφοριών.
        </p>
        <h2 className="title">Επικοινωνία</h2>
        <p>
          Αν έχετε οποιαδήποτε απορία σχετικά με τους όρους χρήσης της
          εφαρμογής, μπορείτε να επικοινωνήσετε μέσω email στο {settingsData.initData.site_email}
        </p>
      </div>
    </div>
  );
};

export default TermsEl;

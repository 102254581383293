import React from "react";
import { useSelector } from "react-redux";

const TermsEn = (props) => {
  const settingsData = useSelector(({ settings }) => settings.settingsData);

  return (
    <div>
      <h1>Terms of Use for the {settingsData.initData.app_name} app</h1>

      <div className="col-md-6">
        {/*<h1 style="text-align:center;">Terms of Use</h1>*/}
        <p>
          This privacy policy governs the use of the{" "}
          <strong>{settingsData.initData.app_name}</strong> mobile application created by FOCUS-ON GROUP.
        </p>
        <h2 className="title" style={{ paddingTop: 20 }}>
          What information does the application obtain and how is it used?
        </h2>
        <h2 className="title">Information provided by the user</h2>
        <p>
          The Application obtains the information you provide when you download
          and register on the Application. Registration is optional. However,
          please note that you may not be able to use some of the features
          offered by the application if you do not register.
          <br />
          <br />
          When you register and use the Application, you provide information
          such as (a) your name, email address, user name, password, and other
          registration information; (b) transaction-related information, such as
          when you make purchases, respond to any offers, or download or use
          applications; (c) information you provide us when you contact us for
          help; (d) information you enter into our system when using the
          Application, such as contact information and project management
          information.
          <br />
          <br />
          Our company is committed to protecting your personal data and complies
          with the new European legislation on Data Protection (GDPR). For the
          proper operation of the application, we need to know who has submitted
          the request. It is necessary to record and process your personal data.
          What personal data do we need:
        </p>
        <ul>
          <li> Your name</li>
          <li> Your address</li>
          <li> Your email address</li>
          <li> Contact phone number </li>
        </ul>
        You can modify the information you have registered at any time by going
        to the account editing option. Account deletion: You can delete your
        account, but you should be aware that this action is irreversible. If
        you change your mind after deleting your account, you will need to
        create a new account. If you want your account to be deleted, you will
        need to go to the contact menu and fill out the contact form.
        <br />
        <br />
        We may also use the information you provided to contact you to provide
        important information, required notices, and marketing promotions.
        <p />
        <h2 className="title">Information you provide to us</h2>
        <p>
          We collect information that you provide directly to us, such as when
          you create or modify your account, request on-demand services, or
          communicate with customer support. This information may include: name,
          email, phone number, postal address, profile picture, payment method,
          items requested (for delivery services), delivery notes, and other
          information you choose to provide.
        </p>
        <h2 className="title">
          Information we collect through your use of our services
        </h2>
        <p>
          When you use our Services, we collect information about you in the
          following general categories:
        </p>
        <p>
          <span className="bold">Location Information:</span> When you use the
          Services for delivery or other functions of the application, we
          collect precise location data from you only when you have allowed the
          use of location services. The application uses this data to provide
          information based on your location.
        </p>
        <p>
          <span className="bold">Usage and Preference Information:</span> We
          collect information about how you interact with our Services,
          preferences expressed, and settings chosen. In some cases, we do this
          through the use of cookies and similar technologies that create and
          maintain unique identifiers.
        </p>
        <p>
          <span className="bold">Device Information:</span> We may collect
          information about your mobile device, such as the hardware model,
          operating system and version, software and file names and versions,
          preferred language, unique device identifier, advertising identifiers,
          serial number, device motion information, and mobile network
          information.
        </p>
        <p>
          <span className="bold">Log Information:</span> When you interact with
          the Services, we collect server logs, which may include information
          such as device IP address, access dates and times, app features or
          pages viewed, app crashes, and other system activity, type of browser,
          and the third-party site or service you were using before interacting
          with our Services.
        </p>
        <h2 className="title">Automatically collected information </h2>
        <p>
          In addition, the application may collect certain information
          automatically, including, but not limited to, the type of mobile
          device you use, your mobile device's unique device ID, the IP address
          of your mobile device, your mobile operating system, the type of
          mobile Internet browsers you use, and information about the way you
          use the application.
        </p>
        <h2 className="title">
          Does the application collect precise real-time location information of
          the device?
        </h2>
        <p>
          When you visit the mobile application, we may use GPS technology (or
          other similar technology) to determine your current location to
          determine the city you are located within and display a location map
          with relevant advertisements. We will not share your current location
          with other users or partners.
          <br />
          <br />
          If you do not want us to use your location for the purposes set forth
          above, you should turn off the location services for the mobile
          application located in your account settings or in your mobile phone
          settings and/or within the mobile application.
        </p>
        <h2 className="title">
          Do third parties see and/or have access to information obtained by the
          application?
        </h2>
        <p>
          Yes. We will share your information with third parties only in the
          ways that are described in this privacy statement.
          <br />
          <br />
          We may disclose user-provided and automatically collected information:
          <br />
          • as required by law, such as to comply with a subpoena or similar
          legal process.
          <br />
          • when we believe in good faith that disclosure is necessary to
          protect our rights, protect your safety or the safety of others,
          investigate fraud, or respond to a government request.
          <br />
          <br />
          • with our trusted services providers who work on our behalf, do not
          have an independent use of the information we disclose to them, and
          have agreed to adhere to the rules set forth in this privacy statement.
          <br />• if FOCUS-ON GROUP is involved in a merger, acquisition, or sale
          of all or a portion of its assets, you will be notified via email
          and/or a prominent notice on our Web site of any change in ownership
          or uses of this information, as well as any choices you may have
          regarding this information.
        </p>
        <h2 className="title">What are my opt-out rights?</h2>
        <p>
          You can stop all collection of information by the application easily
          by uninstalling the application. You may use the standard uninstall
          processes as may be available as part of your mobile device or via the
          mobile application marketplace or network.
        </p>
        <h2 className="title">Data Retention Policy, Managing Your Information</h2>
        <p>
          We will retain User Provided data for as long as you use the
          Application and for a reasonable time thereafter. We will retain
          Automatically Collected information for up to 24 months and thereafter
          may store it in aggregate. If you’d like us to delete User Provided
          Data that you have provided via the Application, please contact us at
          {settingsData.initData.site_email} and we will respond in a
          reasonable time. Please note that some or all of the User Provided
          Data may be required in order for the Application to function
          properly.
        </p>
        <h2 className="title">Security</h2>
        <p>
          We are concerned about safeguarding the confidentiality of your
          information. We provide physical, electronic, and procedural
          safeguards to protect information we process and maintain. For
          example, we limit access to this information to authorized employees
          and contractors who need to know that information in order to operate,
          develop, or improve our Application. Please be aware that, although we
          endeavor to provide reasonable security for information we process and
          maintain, no security system can prevent all potential security
          breaches.
        </p>
        <h2 className="title">Changes</h2>
        <p>
          This Privacy Policy may be updated from time to time for any reason.
          We will notify you of any changes to our Privacy Policy by posting the
          new Privacy Policy here and informing you via email. You are advised
          to consult this Privacy Policy regularly for any changes, as continued
          use is deemed approval of all changes. You can check the history of
          this policy by visiting the app’s settings.
        </p>
        <h2 className="title">Your Consent</h2>
        <p>
          By using the Services, you are consenting to our processing of your
          information as set forth in this Privacy Policy now and as amended by
          us. "Processing," means using cookies on a computer/handheld device or
          using or touching information in any way, including, but not limited
          to, collecting, storing, deleting, using, combining, and disclosing
          information.
        </p>
        <h2 className="title">Contact us</h2>
        <p>
          If you have any questions regarding privacy while using the
          Application, or have questions about our practices, please contact us
          via email at {settingsData.initData.site_email}.
        </p>
      </div>
    </div>
  );
};

export default TermsEn;
